<template>
  <div v-if="dialog" class="popup_overlay">
  <div class="custom_dialog bg-white" style="width:890px;max-height:750px;">
    <div class="cust_card">
      <div class=" rounded px-5 py-4 h-full bg-primary text-white heading-2 font-bold flex items-center justify-between">
        <div>
          <p class="">{{this.userId > 0 ? 'Edit Project' : 'Add Project'}}</p>
        </div>
        <div>
          <button class="list_top_card_btn font-bold bg-gray-200 cta-text text-text1 py-2 px-4 mr-2 rounded-3xl" @click="closeDetails(false)">Discard</button>
          <button class="list_top_card_btn font-bold  bg-success cta-text text-white py-2 px-4 rounded-3xl" @click="addemployeeType()">Save</button>
        </div>  
      </div>
      <div>
        <div class="p-2 grid grid-cols-12">
          <div class="box col-span-12 p-2">
            <p class="label_css">Project Name</p>
            <input
            :class="employeeTypeObjErr.projectErr ? 'border border-error' :'border border-gray-500 focus:border-gray-800'"
            class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4" 
            placeholder="Project Name"
            v-model="filterObject.projectName"
            >
            <p class="text-error" v-if="employeeTypeObjErr.projectErr">Project name is require</p>
          </div>
          <div class="box col-span-12 p-2">
            <p class="label_css">Project Description</p>
            <textarea
            auto-grow=""
            class="outline-none  text-gray-900 text-sm rounded border border-gray-500 focus:border-gray-800 block w-full pl-2 p-2.5 h-24 heading-4" 
            placeholder="Project Description"
            v-model="filterObject.notes"
            ></textarea>
          </div>
          <div class="box col-span-12 p-2">
            <div class="rounded h-full bg-white">
              <div>
                <div fluid class="p-0 ">
                  <div class="bg-white rounded">
                    <div class="w-full">
                      <div class="" >
                        <p class="label_css">Search & Select Employee</p>
                        <div class="py-2 p-3 flex border border-gray-500 bg-white shadow rounded-md " @click.stop="showPopupEmp">
                          <div class="flex flex-auto flex-wrap" >
                            <div class=" contents">
                              <div v-for="(cell, index) in empSelectData" :key="index" class="">
                                <div class="flex text-xs mt-2 mx-1 ml-0 py-1 px-2 rounded-lg border border-teal-300 bg-gray-200 text-gray-800 font-bold">
                                  {{ cell.fullName }}
                                    <div @click="selectEmp(cell, index, 'close')">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x cursor-pointer hover:text-teal-400 rounded-full w-4 h-4 ml-2"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line>
                                      </svg>
                                  </div>
                                </div>
                              </div>
                              <input autocomplete="off" :placeholder="empSelectData.length === 0 ? 'Search & Select Employee' : ''" v-model="searchVal" type="search" class="h-9 focus:outline-none focus:ring-blue-500 focus:border-blue-500 heading-4">
                            </div>
                          </div>
                          <span class="place-self-center">
                            <p v-if="displayEmpSelect"><i class="fas fa-chevron-up"></i></p>
                            <span v-if="!displayEmpSelect"><i class="fas fa-chevron-down"></i></span>
                          </span>
                        </div>
                        <div :class="employeeList.length > 0 ? 'h-auto max-h-64' : 'h-auto max-h-64'"  class="flex-col w-auto  bg-white overflow-auto border border-gray-200 shadow-lg" style="overflow-x: auto;width: 454px;position: fixed;" v-show="displayEmpSelect" v-click-outside="hidePopupEmp">
                          <div v-for="(data, index) in employeeList" :key="index" :class="data.isSelect ? 'bg-blue-100 border-blue-100' : ''" @click="selectEmp(data, index, 'select')" class="flex shadow hover:bg-gray-100 items-center  cursor-pointer p-3 pl-2  border-b hover:bg-teal-100 border-transparent border-l-2 relative hover:border-teal-100" >
                            <input type="checkbox" class="w-4 h-4" v-model="data.isSelect">
                              <div class="pl-5 heading-4">
                                {{ data.fullName }}
                              </div>
                          </div>
                          <p class="p-2 text-lg" v-if="employeeList.length === 0">No Record Found</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="p-4 py-2 flex items-center">
          <input type="checkbox" class="h-5 w-5 cursor-pointer" v-model="filterObject.isActive">
          <p class=" text-text1 pl-2 heading-5">Project is Active</p>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import deboucneMixin from '@/mixins/debounce.js'
import HRM from '@/View/CMS/api/HRM.js'
export default {
  mixins: [deboucneMixin],
  props: ['userId'],
  data () {
    return {
      displayEmpSelect: false,
      empSelectData: [],
      employeeList: [],
      searchVal: '',
      searchForEmployee: '',
      emailOverlap: null,
      passwordVal: '',
      userDetailValue: [],
      filterObject: {
        projectDetailId: 0,
        projectName: '',
        notes: '',
        isActive: true,
        projectAssignToUsers: []
      },
      employeeTypeObjErr: {
        projectErr: false,
        empErr: false,
      },
      submitted: false,
      shiftlist: [],
      selectedShiftId: '',
      Obj2: {},
      Obj: {},
      cardView: false,
      dialog: true,
      employeeTypeList: [],
      getNoOfEmployees: () => {}
    }
  },
  validations: {
  },
  computed: {
  },
  mounted () {
    console.log('userId', this.userId)
    if (this.userId > 0) {
      this.getProjectDetail(this.userId)
    }
    this.callApiAfterDebounce()
    this.getNoOfEmployees = this.debounce(function () {
      this.callApiAfterDebounce()
    }, 500)
  },
  watch: {
    filterObject: {
      handler () {
        console.log("filterObject", this.filterObject)
        if (this.filterObject.projectName !== '') {
          this.employeeTypeObjErr.projectErr = false
        }
      },
      deep: true
    },
    searchVal: {
      handler() {
        console.log('less than 0', this.searchVal.length)
        if (this.searchVal.length > 0) {
          this.searchForEmployee = this.searchVal
          this.callApiAfterDebounce()
        } else {
          console.log('less than 0', this.searchVal.length)
          this.searchForEmployee = ''
          this.callApiAfterDebounce()
        }
      },
      deep: true,
    },
    empSelectData: {
      handler () {
        if (this.empSelectData !== null) {
          this.filterObject.projectAssignToUsers = []
          this.empSelectData.forEach(a => {
            let obj = {
              firstName: a.firstName,
              lastName: a.lastName,
              userDetailId: a.userDetailId
            }
            this.filterObject.projectAssignToUsers.push(obj)
          })
        } else {
          this.filterObject.projectAssignToUsers = []
        }
      },
      deep: true
    }
  },
  methods: {
    selectEmp (data, indexA, val) {
      if (val === "select") {
        if (this.employeeList[indexA].userDetailId === data.userDetailId && data.isSelect === false) {
          this.employeeList.forEach(a => {
            if (a.userDetailId === data.userDetailId) {
              a.isSelect = true
              this.empSelectData.push(a)
              console.log('', this.empSelectData)
              console.log('this.employeeList', this.employeeList)
            }
          })
        } else {
          const findex = this.empSelectData.findIndex(x => x.userDetailId === data.userDetailId)
          this.employeeList[indexA].isSelect = false
          this.empSelectData.splice(findex, 1)
        }
      } else if (val === "close") {
          this.displayEmpSelect = false
          const findex = this.empSelectData.findIndex(x => x.userDetailId === data.userDetailId)
          this.employeeList.forEach(a => {
            if (a.userDetailId === data.userDetailId) {
              a.isSelect = false
            }
          })
          this.empSelectData.splice(findex, 1)
      }
    },
    showPopupEmp() {
      this.displayEmpSelect = true
    },
    hidePopupEmp() {
      this.displayEmpSelect = false
    },
    resizeGrid () {
      if (window.innerWidth < 960) {
        this.cardView = true
      } else {
        this.cardView = false
      }
    },
    addTag (newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
      }
      this.options.push(tag)
      this.value.push(tag)
    },
    getProjectDetail (ID) {
      this.$store.dispatch('SetLoader', {status: true, message: 'Please Wait...'})
      HRM.getSpecProjectDetail(
        ID,
        response => {
          console.log(response.Data)
          let temp = response.Data
          this.filterObject.projectDetailId = temp.projectDetailId
          this.filterObject.projectName = temp.projectName
          this.filterObject.notes = temp.notes
          this.filterObject.isActive = temp.isActive
          let data = JSON.parse(temp.projectAssignToUsers)
          if (data.projectUsers !== null) {
            for (let indexAA = 0; indexAA < data.projectUsers.length; indexAA++) {
              data.projectUsers[indexAA].fullName = data.projectUsers[indexAA].firstName + ' ' + data.projectUsers[indexAA].lastName
              this.filterObject.projectAssignToUsers.push(data.projectUsers[indexAA]);
              this.empSelectData.push(data.projectUsers[indexAA]);
              this.callApiAfterDebounce()
            }
          }
          console.log('this.filterObject', this.filterObject)
          // this.filterObject.projectAssignToUsers = temp.projectAssignToUsers
          this.$store.dispatch('SetLoader', {status: false, message: 'Please Wait...'})
          this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
        },
        error => {
          // console.log(error)
          this.$store.dispatch('SetLoader', {status: false, message: 'Please Wait...'})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    addemployeeType () {
      if (this.filterObject.projectName === '') {
        this.employeeTypeObjErr.projectErr = true
      }
      if (this.employeeTypeObjErr.projectErr === false) {
        // console.log(this.employeeTypeObj)
        this.$store.dispatch('SetLoader', {status: true, message: 'Please Wait...'})
        HRM.addNewProject(
          this.filterObject,
          response => {
            // console.log(response)
            this.$root.$emit('closeDialog', false)
            this.$store.dispatch('SetLoader', {status: false, message: 'Please Wait...'})
            this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
          },
          error => {
            // console.log(error)
            this.$store.dispatch('SetLoader', {status: false, message: 'Please Wait...'})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        )
      }
    },
    callApiAfterDebounce () {
      HRM.getUserList(
        0,
        20,
        this.searchForEmployee,
        'addedDate',
        'DESC',
        true,
        response => {
          console.log("response", response.Data)
          this.employeeList = []
          let employeeList = response.Data.tableRow === null ? [] : response.Data.tableRow
          for (let indexAA = 0; indexAA < employeeList.length; indexAA++) {
            employeeList[indexAA].isSelect = false;
            employeeList[indexAA].fullName = employeeList[indexAA].firstName + ' ' + employeeList[indexAA].lastName
            this.employeeList.push(employeeList[indexAA]);
          }
          if (this.employeeList.length > 0 && this.empSelectData.length > 0) {
            for (let i = 0; i < this.employeeList.length; i++) {
              for (let j = 0; j < this.empSelectData.length; j++) {
                if (this.employeeList[i].userDetailId === this.empSelectData[j].userDetailId) {
                    this.employeeList[i].isSelect = true;
                }
              }
            }
          }
          console.log('this.employeeList', this.employeeList)
        },
        error => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    closeDetails () {
      this.$root.$emit('closeDialog', false)
    }
  }
}
</script>
<style scoped>
.profiles {
  display: flex;
  align-items: center;
  justify-content: center;
}
.v-item-group,
.v-expansion-panels {
  z-index: 0 !important;
}
.v-expansion-panel-content__wrap {
  padding: 0 0 20px 0 !important;
}
.content {
  padding: 5px 5px;
}
.checkbox {
  color: grey;
  font-size: 14px;
}
.checkbox1 {
  color: grey;
  font-size: 11px;
}
.checkbox2 {
  color: grey;
  font-size: 11px;
}
.buttons .button {
  margin-bottom: 0rem;
}
.input:focus {
  outline: none;
  border-color: 0 0 0 0.125em rgba(31, 82, 190, 0.25);
  box-shadow: none;
}
a.dropdown-item.is-active,
.dropdown .dropdown-menu .has-link a.is-active,
button.dropdown-item.is-active {
  background-color: white;
  color: black;
}
.datetimepicker .datetimepicker-header {
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: left;
  position: relative;
  padding: 0.3rem 1rem;
}
.box:not(:last-child),
.content:not(:last-child),
.notification:not(:last-child),
.progress:not(:last-child),
.table:not(:last-child),
.table-container:not(:last-child),
.title:not(:last-child),
.subtitle:not(:last-child),
.block:not(:last-child),
.highlight:not(:last-child),
.breadcrumb:not(:last-child),
.level:not(:last-child),
.list:not(:last-child),
.message:not(:last-child),
.tabs:not(:last-child) {
  margin-bottom: 0rem;
}
.autocomplete .dropdown-content {
  overflow: auto;
  max-height: 200px;
}
.buttons:last-child {
  margin-bottom: 0rem;
}
</style>
