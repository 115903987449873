<template>
  <div class="h-full">
    <div class="bottom_space rounded py-2 h-full bg-white">
      <div class="bottom_space flex justify-between p-2">
        <div v-if="IsSuperAdmin">
          <button class="list_top_card_btn heading-4 bg-primary cta-text text-white py-3 px-5 rounded-3xl" @click="addUserPopup('0')">Add New Project</button>
        </div>
        <div class="pt-1">
          <div class="pb-2">
            <button class="mr-3 text-sm border heading-5 hover:bg-info py-2.5 text-text1 rounded-md list_btn" :class="statusVal ? 'bg-info border-info' : 'bg-white border-gray-500'" @click="statusChip('active')">Active</button>
            <button class="mr-3 text-sm border heading-5 hover:bg-info py-2.5  text-text1 rounded-md list_btn" :class="!statusVal ? 'bg-info border-info' : 'bg-white border-gray-500'" @click="statusChip('inactive')">Inactive</button>
          </div>
          </div>
      </div>
      <div>
      <div class="w-full" >
        <div class="progress-bar" v-if="searchLoader">
          <div class="progress-bar-value"></div>
        </div>
      </div>
      <div style="overflow-y: auto; white-space: nowrap;" class="table_containder " v-if="!mobileView">
        <div class="text-text2  min-w-min heaer_row border border-gray1 font-bold flex heading-5 px-3 py-3 items-center">
          <div class="name_col flex items-center p-2">
            <span class="cursor-pointer" @click="sortByColumn(0)">{{titleObj[0].title}} </span>
            <img v-if="titleObj[0].sortVal === 'DESC'" src="@/assets/images/svg/DESC.svg" /> 
            <img v-if="titleObj[0].sortVal === 'ASC'" src="@/assets/images/svg/ASC.svg" /> 
          </div>
          <div class="desc_col  flex items-center p-2">
            <span class="cursor-pointer" @click="sortByColumn(1)">{{titleObj[1].title}}</span>
            <img class="pl-1" v-if="titleObj[1].sortVal === 'DESC'" src="@/assets/images/svg/DESC.svg" /> 
            <img class="pl-1" v-if="titleObj[1].sortVal === 'ASC'" src="@/assets/images/svg/ASC.svg" /> 
          </div>
          <div class="cell_col  flex items-center p-2">
            <span class="cursor-pointer" @click="sortByColumn(2)">{{titleObj[2].title}}</span>
            <img class="pl-1" v-if="titleObj[2].sortVal === 'DESC'" src="@/assets/images/svg/DESC.svg" /> 
            <img class="pl-1" v-if="titleObj[2].sortVal === 'ASC'" src="@/assets/images/svg/ASC.svg" /> 
          </div>
          <div class="created_col  flex items-center p-2">
            <span class="cursor-pointer" @click="sortByColumn(3)">{{titleObj[3].title}}</span>
            <img class="pl-1" v-if="titleObj[3].sortVal === 'DESC'" src="@/assets/images/svg/DESC.svg" /> 
            <img class="pl-1" v-if="titleObj[3].sortVal === 'ASC'" src="@/assets/images/svg/ASC.svg" /> 
          </div>
          <div class="cell_col  flex items-center p-2">
            <span class="cursor-pointer" @click="sortByColumn(4)">{{titleObj[4].title}}</span>
            <img class="pl-1" v-if="titleObj[4].sortVal === 'DESC'" src="@/assets/images/svg/DESC.svg" /> 
            <img class="pl-1" v-if="titleObj[4].sortVal === 'ASC'" src="@/assets/images/svg/ASC.svg" /> 
          </div>
        </div>
        <div v-for="(data, index) in employeeList" :key="index" class="min-w-min group   flex border-r border-l border-b border-gray1  bg-white  text-sm px-3 py-2 items-center cursor-pointer hover:bg-gray-50">
          <div class="name_col p-2" @click="showUserDetail(data.projectDetailId)">
            <span class="heading-4  text-text2 font-medium">{{data.projectName}}</span>
          </div>
          <div class="desc_col p-2" @click="showUserDetail(data.projectDetailId)">
            <span class="heading-4  text-text2 font-medium" v-if="data.notes !== ''">{{data.notes.substring(0,100)}}<span v-if="data.notes.length > 100">....</span></span>
            <span class="heading-4  text-text2 font-medium" v-if="data.notes === ''">---</span>
          </div>
          <div class="cell_col p-2" @click="showUserDetail(data.projectDetailId)">
            <span class="heading-4  text-text2 font-medium">{{data.lastUpdated | dateTimeFilter}}</span>
          </div>
          <div class="created_col p-2" @click="showUserDetail(data.projectDetailId)">
            <span class="heading-4  text-text2 font-medium">{{data.createdFirstName}} {{data.createdLastName}}</span>
          </div>
          <div class="cell_col p-2" @click="showUserDetail(data.projectDetailId)">
            <span class="heading-4 text-text2" v-if="data.addedDate !== ''">{{data.addedDate | dateTimeFilter}}</span>
            <span class="" v-else>--</span>
          </div>
          <div class="cell_col p-2" @click="showUserDetail(data.projectDetailId)">
            <div v-if="data.array !== null" class="flex flex-wrap">
              <div v-for="(accepm, index1) in data.array" :key="index1" class="cursor-pointer max-w-max rounded-full mr-2  border-2 border-primary overflow-hidden mb-1">
                <div  class="row_user_svg border-2 bg-primary border-primary overflow-hidden rounded-full flex justify-center items-center">
                  <span class="text-white heading-4 font-bold">{{accepm.firstName | SubStringFilter(0, 2)}}</span>
                </div>
              </div>
            </div>
            <div v-if="data.array === null" class="row_user_svg border-2 bg-primary border-primary overflow-hidden rounded-full flex justify-center items-center">
              <i class="fas fa-user-alt-slash text-white heading-4"></i>
            </div>
          </div>
          <div class="cell_col p-2" v-if="IsSuperAdmin">
            <p class="invisible group-hover:visible ">
              <span class="px-1 pr-3 cursor-pointer" @click="addUserPopup(data.projectDetailId)">
                <i class="fas fa-edit text-primary h-5 w-5" ></i>
              </span>
            </p>
          </div>
        </div>
      </div>
      <div class="layout my-2" v-if="employeeList.length === 0">
        <div class="flex p-3 text-sm text-primary bg-blue-100 rounded-lg  items-center" role="alert">
          <svg aria-hidden="true" class="flex-shrink-0 inline w-6 h-6 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
          <span class="sr-only">Info</span>
          <div>
            <p class=" font-medium heading-4">No record found.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="pt-2">
      <Pagination
        :totalDataCount="totalEmpCount"
        :resetPagination="resetPaginationValues"
        @callApiAsPerPagination="getCustomerDataFromPagination"/>
    </div>
  </div>
  <div v-if="adduserDialog" class="popup_overlay">
    <div class="bg-white rounded-sm p-4 custom_dialog" style="width:550px">
      <addUser :userId="userDetailId"></addUser>
    </div>
  </div>
</div>
</template>
<script>
import HRMAPI from '@/View/CMS/api/HRM.js'
import addUser from '@/View/CMS/project/addProject.vue'
// import Pagination from '@/View/components/pagination.vue'
import Pagination from '@/View/components/pagination.vue'
import {FilterPermissions} from '@/utils/Permissions.js'
import deboucneMixin from '@/mixins/debounce.js'
export default {
  components: {
    Pagination,
    addUser
  },
  mixins: [deboucneMixin],
  data () {
    return {
      IsSuperAdmin: false,
      userDetailValue: [],
      mobileView: false,
      adduserDialog: false,
      istrue: true,
      userDetailId: 0,
      filterApplied: false,
      statusVal: true,
      navigationDialog: false,
      isFilterApplied: false,
      searchLoader: false,
      resetPaginationValues: false,
      showCustomerAddDialog: false,
      searchForCustomer: '',
      titleObj: [
        {title: 'Project Name', icon: '', sortName: 'projectName', sortVal: ''},
        {title: 'Note', icon: '', sortName: 'notes', sortVal: ''},
        {title: 'Last Updated On', icon: '', sortName: 'lastUpdated', sortVal: ''},
        {title: 'Created By', icon: '', sortName: 'createdFirstName', sortVal: ''},
        {title: 'Created On', icon: 'fas fa-sort-amount-up', sortName: 'addedDate', sortVal: 'DESC'},
    ],
      cancelFilter: {
        selectedBranch: [],
        selectedDepartment: []
      },
      filterObject: {
        start: 0,
        length: 20,
        searchVal: '',
        sortBy: 'addedDate',
        sortOrder: 'DESC',
        isActive: true,
      },
      totalEmpCount: 0,
      paginationLength: 1,
      filterCol: 'text-gray-400',
      page: 1,
      items: [20, 25, 30, 50, 100],
      limitResData: 20,
      paginationData: 20,
      employeeList: [],
      canAdd: FilterPermissions('customer_account__add_new')
    }
  },
  created() {
    window.addEventListener("resize", this.resizeWindowHandler);
  },
  mounted () {
    let Isadmin =  JSON.parse(localStorage.getItem('employeeData1'))
    this.IsSuperAdmin = Isadmin.isSuperAdmin
    this.resizeWindowHandler();
    document.title = 'Project List'
    this.getContactListDeboucne = this.debounce(function () {
      this.resetPaginationValues = true
    }, 500)
    this.getEmployeeList()
    this.$root.$on("closeDialog", (data) => {
      if (data === false) {
        this.adduserDialog = false
        // this.getEmployeeList()
      }
      this.getEmployeeList()
    });
  },
  watch: {
    searchForCustomer: {
      handler () {
        if (this.searchForCustomer === null) {
          this.searchForCustomer = ''
        }
      }
    },
    titleObj: {
      handler () {
        console.log('titleObj 123', this.titleObj)
      }
    },
    filterApplied: {
      handler () {
        console.log('filterApplied 123', this.filterApplied)
        if (this.filterApplied === true) {
          this.filterCol = 'text-primary'
        } else {
          this.filterCol = 'text-success'
        }
      }
    },
    paginationData: {
      handler () {
        this.filterObject.length = this.paginationData
        this.showlistLoader = true
        this.getEmployeeList(this.showlistLoader)
      },
      deep: true
    }
  },
  methods: {
    resizeWindowHandler() {
      if (window.innerWidth < 684) {
        this.mobileView = true;
      } else {
        this.mobileView = false;
      }
    },
    showUserDetail (data) {
      this.$router.push({name: 'Pages', params: {projectId: data}})
      console.log('data', data)
    },
    addUserPopup (ID) {
      console.log('userId ID', ID)
      this.userDetailId = ID
      this.adduserDialog = true
    },
    customerSearch () {
      document.getElementById('custSearchInput').focus()
    },
    clearSearch () {
      this.searchForCustomer = ''
      this.searchLoader = false
      this.getEmployeeList()
    },
    getCustomerDataFromPagination (data) {
      this.filterObject.start = data.offset
      this.filterObject.length = data.limit
      let showlistLoader = false
      if (data.offset !== 0) {
        showlistLoader = true
      }
      this.getEmployeeList(showlistLoader)
    },
    sortByColumn (index) {
      for (let i = 0; i < this.titleObj.length; i++) {
        if (i === index) {
          if (this.titleObj[i].icon === '') {
            this.titleObj[i].icon = 'fas fa-sort-amount-down'
            this.titleObj[i].sortVal = 'DESC'
            this.filterObject.sortOrder = 'DESC'
          } else if (this.titleObj[i].icon === 'fas fa-sort-amount-down') {
            this.titleObj[i].icon = 'fas fa-sort-amount-up'
            this.titleObj[i].sortVal = 'ASC'
            this.filterObject.sortOrder = 'ASC'
          } else {
            this.titleObj[i].icon = 'fas fa-sort-amount-down'
            this.filterObject.sortOrder = 'DESC'
            this.titleObj[i].sortVal = 'DESC'
          }
        } else {
          this.titleObj[i].icon = ''
          this.titleObj[i].sortVal = ''
        }
      }
      this.filterObject.sortBy = this.titleObj[index].sortName
      this.getEmployeeList(true)
    },
    redirectToDetail (employeeId) {
      if (FilterPermissions('employee__detail_view')) {
        this.$router.push({name: 'EmployeeDetail', params: {employeeId: employeeId}})
      }
    },
    toTop () {
      this.$vuetify.goTo(0)
    },
    statusChip (val) {
      console.log(val)
      if (val === 'active') {
        this.statusVal = true
        this.filterObject.isActive = true
      } else if (val === 'inactive') {
        this.statusVal = false
        this.filterObject.isActive = false
      }
      this.getEmployeeList()
    },
    getLeaveDataAsPerPagination1 (data) {
      this.filterObject.length = data
      this.getEmployeeList()
    },
    getEmployeeList () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      HRMAPI.getProjectList(
        this.filterObject.start,
        this.filterObject.length,
        this.filterObject.searchVal,
        this.filterObject.sortBy,
        this.filterObject.sortOrder,
        this.filterObject.isActive,
        response => {
          console.log("response", response.Data)
          this.resetPaginationValues = false
          let employeeList = response.Data.tableRow === null ? [] : response.Data.tableRow

          for (let indexAA = 0; indexAA < employeeList.length; indexAA++) {
            let data = JSON.parse(employeeList[indexAA].projectAssignToUsers)
            console.log('employeeList 1 s', data.projectUsers)
            if (data.projectUsers !== null) {
              employeeList[indexAA].array = data.projectUsers
            } else {
              employeeList[indexAA].array = null
            }
            this.employeeList = employeeList
          }
          console.log(' r whfhi ghydgfyugdyusgwdsduyfgsyu gyu gsyuf g', this.employeeList)
          this.totalEmpCount = response.Data.count
          console.log('count', this.totalEmpCount)
          this.searchLoader = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        error => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    }
  },
  beforeDestroy () {
    localStorage.removeItem('filterDataOfEmployee')
    this.$root.$off('Filter')
    this.$root.$off('Filters')
  }
}
</script>
<style scoped>
.table_containder {
  padding: 1px;
}
.name_col {
  min-width: 200px !important;
  width: 200px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.desc_col {
  min-width: 310px !important;
  width: 310px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.created_col {
  min-width: 220px !important;
  width: 220px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.cell_col {
  min-width: 258px !important;
  width: 258px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.list_btn {
  min-width: 85px !important;
  max-width: 85px;
}
.select_btn {
  min-width: 77px !important;
  max-width: 77px;
}
.select_btn:focus-visible {
  outline: none;
}
.search_customer_box {
  min-width: 335px !important;
  max-width: 335px;
}
.list_top_card_btn {
  width: 183px !important;
  margin-right: 15px !important;
}
.row_user_svg {
  height: 36px;
  width: 36px;
}
.navbar-open {
  transform: translateX(0%);
}
.navbar-close {
  transform: translateX(-100%);
}
.demo-container {
  width: 300px;
  margin: auto;
}

.progress-bar {
  height: 2px;
  background-color: rgb(18,149,186);
  width: 100%;
  overflow: hidden;
}

.progress-bar-value {
  width: 100%;
  height: 100%;
  background-color: rgb(121, 200, 221);
  animation: indeterminateAnimation 2s infinite linear;
  transform-origin: 0% 50%;
}

@keyframes indeterminateAnimation {
  0% {
    transform:  translateX(0) scaleX(0);
  }
  40% {
    transform:  translateX(0) scaleX(0.4);
  }
  100% {
    transform:  translateX(100%) scaleX(0.5);
  }
}
::placeholder {
  font-size: 16px;
}
.lableWidth {
  width: 105px;
}
.dotwidth {
  width: 15px;
}
</style>
